<template>
  <div class="footer-container">
    <ul class="tool-list">
      <li v-for="collection in toolList" :key="collection.name">
        <Collapse :handleToggle="handleCollapse" :handleResize="handleCollapseResize">
          <template #summary>
            {{ collection.name }}
          </template>
          <ul class="item-list">
            <li v-for="item in collection.list" :key="item.title" @click="handleRouter(item)">
              {{ item.title }}
            </li>
          </ul>
        </Collapse>
      </li>
    </ul>
    <div style="margin-top: 64px">
      <img class="boolv-icon" :src="boolvIcon" />
      <ul class="social-media-list" @click="handleClickLink">
        <li
          v-for="item in socialMediaList"
          :key="item.name"
          :data-link="item.link"
        >
          <img class="social-media-icon" :src="item.icon"/>
          {{ item.name }}
        </li>
      </ul>
      <p class="copyright">@2022 Boolv.tech. All rights reseved</p>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import Collapse from '@/components/collapse.vue';
import { eventTracking } from '@/utils/eventTracking.js';
import boolvIcon from '@/assets/home/footer-logo.svg';
import { showModal, currentToolName } from '@/views/homePage/viewModel';
import { toolList, socialMediaList } from '../data.config';

let expandedNode = null;
const router = useRouter();

function handleRouter(item) {
  eventTracking("booltool_homepage_click", { tool_name: item.trackName, trigger_location: 'footer' })
  // if (["/face-swapper", "/video-background-remove", "/video-inpainting"].includes(item.path)) {
  //   currentToolName.value = item.trackName
  //   showModal.value = true
  //   return
  // }
  const url = router.resolve({ path: item.path }).href;
  window.open(url, '_blank')
}

function handleClickLink(e) {
  const target = e.target.closest('[data-link]');

  if (target !== null) {
    const link = target.dataset.link;
    window.open(link, /^https?:/.test(link) ? '_blank' : '_self');
  }
}

function handleCollapse(isOpen, contentRef) {
  contentRef.parentElement.style.height = isOpen ? `${contentRef.clientHeight}px` : 0;

  if (expandedNode !== null && expandedNode !== contentRef) {
    expandedNode.parentElement.style.height = '0';
    expandedNode.closest('.x-collapse').classList.remove('open');
  }

  expandedNode = contentRef;
}

function handleCollapseResize({ target }) {
  target.parentElement.style.height = `${target.clientHeight}px`;
}
</script>

<style scoped>
.footer-container {
  @apply px-sv;

  width: 100%;
  padding-block: 28px 88px;
  color: #fff;
  text-align: left;
  letter-spacing: .5px;
}

.tool-list {
  margin-inline: auto;
}

.tool-list > li {
  padding-block: 28px;
  border-bottom: 1px solid #333;
}

.x-collapse:deep > .x-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
}

.x-collapse:deep > .x-summary::after {
  content: '';
  display: block;
  width: 25px;
  height: 25px;
  background: url('../assets/icon_arrow.svg') no-repeat center/50%;
  transition: transform .2s;
  cursor: pointer;
}

.x-collapse.open:deep > .x-summary::after {
  transform: rotate(180deg);
}

.x-collapse:deep > .x-container {
  height: 0;
  transition: height .2s;
}

.x-collapse:deep .x-content {
  padding-top: 20px;
}

.item-list {
  display: grid;
  font-size: 16px;
  line-height: 1.8;
  gap: 14px 40px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.tool-list > li, .item-list > li {
  cursor: pointer;
}

.social-media-list {
  display: grid;
  margin-top: 45px;
  font-size: 16px;
  line-height: 19px;
  gap: 27px 40px;
  grid-template-columns: 1fr 1fr;
}

.social-media-list > li {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.social-media-icon {
  display: inline-block;
  width: 18px;
  max-height: 16px;
  margin-right: 12px;
}

.copyright {
  margin-top: 40px;
  font-size: 14px;
  color: #D2D2D2;
}

@screen s {
  .x-collapse:deep > .x-summary {
    font-size: 22px;
  }

  .item-list {
    font-size: 15px;
  }

  .tool-list > li {
    padding-block: 25px;
  }
}
</style>