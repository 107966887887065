<template>
  <footer class="footer-container">
    <div class="container-footer">
      <div class="container-left">
        <div class="container-logo">
          <img :src="footer_logo" />
          <ul class="social-media-list">
            <li v-for="item in socialMediaList" :key="item.name">
              <a :href="item.link">
                <img :src="item.icon"/>
                {{ item.name }}
              </a>
            </li>
          </ul>
        </div>
        <div class="left-text">@2023 Boolv.tech. All rights reseved</div>
      </div>
      <div class="container-right">
        <div v-for="collection in toolList" :key="collection.name" class="container-nav">
          <div class="nav-item">
            <span class="nav-item-category">{{ collection.name }}</span>
            <div v-for="item in collection.list" :key="item.path" class="tool" @click="handleRouter(item)">
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="nav-item policy">
          <span class="nav-item-category">Company</span>
          <a href="mailto:support@boolvector.com" class="tool">Contact us</a>
          <a href="https://boolv.tech/aboutus" target="_blank" class="tool">Terms of Service</a>
          <router-link to="/terms" class="tool">Terms of Service</router-link>
          <router-link to="/privacy" class="tool">Privacy Policy</router-link>
          <router-link to="/cookies" class="tool">Cookie Policy</router-link>
        </div>
      </div>
    </div>
  </footer>
</template>
<script setup>
import footer_logo from '@/assets/home/footer-logo.svg'
import { eventTracking } from '@/utils/eventTracking.js'
import { useRouter } from 'vue-router'
import { showModal, currentToolName } from '@/views/homePage/viewModel'
import { toolList, socialMediaList } from '../data.config'

const router = useRouter()

function handleRouter(item) {
  eventTracking("booltool_homepage_click", { tool_name: item.trackName, trigger_location: 'footer' })
  // if (["/face-swapper", "/video-background-remove", "/video-inpainting"].includes(item.path)) {
  //   currentToolName.value = item.trackName
  //   showModal.value = true
  //   return
  // }
  const url = router.resolve({ path: item.path }).href;
  window.open(url, '_blank')
}
</script>

<style lang="less" scoped>
.footer-container {
  background: #1C1C1C;
  width: 100%;
  color: #ffffff;
  padding-top: 65px;
  padding-bottom: 89px;
  display: flex;
  justify-content: center;
  align-items: center;

  .container-footer {
    @apply w-lv;
    
    height: 100%;
    display: flex;
    justify-content: space-between;

    .container-left {
      display: flex;
      flex-direction: column;

      .container-logo {
        flex: 1;
      }

      .left-text {
        opacity: 0.8;
      }
    }

    .container-right {
      display: flex;

      .nav-item {
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        margin-right: 64px;
      }
    }
  }
}

.tool {
  cursor: pointer;
  text-align: left;
  margin-top: 18px;
  // opacity: 0.8;
  color: #8F959E;


  &:hover {
    transition: color 0.3s;
    color: #9774FF;
  }
}

.policy {
  display: flex;
  flex-direction: column;
  margin-right: 0px !important;
}

.social-media-list {
  text-align: left;
  line-height: 21px;
}

.social-media-list > li {
  margin: 32px 0;
}

.social-media-list img {
  display: inline-block;
  width: 18px;
  max-height: 16px;
  margin-right: 12px;
}

.social-media-list a {
  display: flex;
  align-items: center;
}

.social-media-list a:hover {
  color: inherit;
}

.nav-item-category {
  line-height: 36px;
}

.nav-item-category + .tool {
  margin-top: 32px;
}
</style>