import { toolsConfig } from "@/components/models/config.js";
import facebookIcon from './assets/icon_facebook.svg';
import twitterIcon from './assets/icon_twitter.svg';
import youtubeIcon from './assets/icon_youtube.svg';
import instagramIcon from './assets/icon_instagram.svg';
import linkedinIcon from './assets/icon_linkedin.svg';
import pinterestIcon from './assets/icon_pinterest.svg';
import mediumIcon from './assets/icon_medium.svg';

export const socialMediaList = [
  {
    icon: twitterIcon,
    name: 'Twitter',
    link: 'https://twitter.com/boolvtech',
  },
  {
    icon: youtubeIcon,
    name: 'Youtube',
    link: 'https://www.youtube.com/channel/UCSPT_tV0QfnGsC4KFSVDSkw',
  },
  {
    icon: pinterestIcon,
    name: 'Pinterest',
    link: 'https://www.pinterest.com/boolv_tech/',
  },
  {
    icon: instagramIcon,
    name: 'Instagram',
    link: 'https://www.instagram.com/boolvtech/',
  },
  {
    icon: mediumIcon,
    name: 'Medium',
    link: 'https://medium.com/@boolv',
  },
  {
    icon: linkedinIcon,
    name: 'Linkedin',
    link: 'https://www.linkedin.com/company/boolv-tech',
  },
  {
    icon: facebookIcon,
    name: 'Facebook',
    link: 'https://www.facebook.com/Boolv-Tech-106608845463051',
  }
];

export const toolList = toolsConfig.map(({collection, children}) => {
  const list = [];

  for (const item of children) {
    list.push({
      title: item.title,
      path: item.path,
      trackName: item.trackName,
    });
  }

  return { name: collection, list };
});