<template>
  <div class="footer-view">
    <FooterInPC v-if="isPC"/>
    <FooterInPE v-else/>
  </div>
</template>

<script setup>
import { computed } from 'vue';
import { useWindowSize } from '@vueuse/core';
import FooterInPC from './components/FooterInPC.vue';
import FooterInPE from './components/FooterInPE.vue';
import { tailwindConfig as tailwindSharedConfig } from '@/shared.config.js';

const { width: innerWidth } = useWindowSize();
const isPC = computed(() => tailwindSharedConfig.screens.m.max < innerWidth.value);
</script>

<style scoped>
.footer-view {
  display: flex;
  justify-content: center;
  width: 100%;
  background-color: black;
}
</style>